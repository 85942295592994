import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { DropdownOption, JoinedFormFieldProps } from 'types';
import { isItemInOptions, shiftDropdownOptionsRight } from 'utils/services';
import { getAdminPanelBoard, getCustomerData } from 'api';
import { DropdownOptionList } from './DropdownOptionList';
import { DropdownCaret } from './DropdownCaret';
import { DropdownSearchInput } from './DropdownSearchInput';
import { NextIcon } from '../NextImage';

import cx from 'classnames';
import styles from './Dropdown.module.scss';

export const Dropdown = ({
  api,
  blockUpdateByValue,
  customClass,
  errorMessage,
  fieldName = '',
  headerStyle,
  hint,
  instanceCallback,
  isThin = false,
  label,
  noWrapper,
  onChange,
  options = [],
  placeholder,
  required,
  searchParams = {},
  showErrors,
  trackFieldChanges,
  trackFieldErrors,
  userApi,
  value,
  withSearch = !!api || !!userApi,
}: JoinedFormFieldProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isApiMode = !!api || !!userApi;
  const [isValid, setIsValid] = useState(!required || value);
  const [currentValue, setCurrentValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const noItemsToSelect = !options.length;

  useEffect(() => {
    if (blockUpdateByValue || typeof value === 'undefined') return;
    onChange && onChange(fieldName, value);
    trackFieldChanges && trackFieldChanges(fieldName, currentValue !== value);
    setCurrentValue(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    trackFieldErrors && trackFieldErrors(required ? isValid : true, fieldName, errorMessage);
    return () => {
      trackFieldErrors && trackFieldErrors(true, fieldName, errorMessage);
    };
  }, [isValid, required]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !required ? setIsValid(true) : setIsValid(!!currentValue);
    trackFieldChanges && trackFieldChanges(fieldName, currentValue !== value);
  }, [currentValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!filteredOptions || !filteredOptions.length || !isItemInOptions(currentValue, filteredOptions)) {
      setCurrentValue(undefined);
    }
    if (value && filteredOptions?.length && isItemInOptions(value, filteredOptions)) {
      setCurrentValue(value);
    }
  }, [filteredOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleOpen = () => {
    if (isOpen) {
      document.removeEventListener('click', onClickOutsideListener);
    } else {
      setFilteredOptions(options);
    }
    setIsOpen(!isOpen);
  };

  const onClickOutsideListener = () => {
    if (isOpen) {
      setIsOpen(false);
      document.removeEventListener('click', onClickOutsideListener);
    }
  };

  const trackChanges = (newValue: string | number | null) => {
    toggleOpen();
    onChange && onChange(fieldName, newValue);
    if (filteredOptions && instanceCallback) {
      // @ts-ignore
      const optionInstance = filteredOptions.find(
        (option: DropdownOption) => option.id === newValue || option.value === newValue,
      );
      instanceCallback(fieldName, optionInstance);
    }
    setCurrentValue(newValue);
  };

  const findOptionItem = () => {
    // @ts-ignore
    return filteredOptions.find(
      (option: DropdownOption) => option.value === currentValue || option.id === currentValue,
    );
  };

  const showOptionCaption = () => {
    if (typeof currentValue === 'undefined') {
      return isOpen ? hint : null;
    }
    const selectedItem = findOptionItem();
    const label = selectedItem?.label;
    const title = selectedItem?.title;
    const composedName = selectedItem?.first_name && `${selectedItem.first_name} ${selectedItem.last_name || ''}`;
    return selectedItem?.url ? (
      <>
        <span>
          <NextIcon src={selectedItem.url} />
        </span>{' '}
        {label || title || composedName}
      </>
    ) : (
      <p>{label || title || composedName}</p>
    );
  };

  const onSearch = (search: string) => {
    if (isApiMode) {
      handleApiSearch(search).then();
    } else {
      handleLocalSearch(search);
    }
  };

  const handleLocalSearch = (search: string) => {
    if (!search) {
      setFilteredOptions(options);
    }
    setFilteredOptions(
      // @ts-ignore
      options?.filter(
        (option: DropdownOption) =>
          (option.title || option.label) &&
          (option.title || option.label)!.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  const handleApiSearch = async (search: string) => {
    if (api) {
      const response = await getAdminPanelBoard(api, { search: search, limit: 100, simplify: true, ...searchParams });
      if (response.ok) {
        const { data } = response;
        setFilteredOptions(data || []);
      }
    }

    if (userApi) {
      const response = await getCustomerData(userApi, {
        q: search,
        search: search,
        limit: 100,
        simplify: true,
        ...searchParams,
      });
      if (response.ok) {
        const { data, hits } = response;
        setFilteredOptions(data || hits || []);
      }
    }
  };

  const warnAboutError = !isValid && showErrors;
  const showErrorMessages = errorMessage && warnAboutError;

  return (
    <div
      ref={containerRef}
      onMouseLeave={() => {
        document.addEventListener('click', onClickOutsideListener);
      }}
      className={cx(
        styles.container,
        {
          [styles.errorContainer]: showErrorMessages,
          [styles.indent]: !showErrorMessages && !noWrapper,
        },
        customClass,
      )}
    >
      {label && <p className={styles.label}>{label}</p>}
      <div
        className={cx(styles.select, {
          [styles.thinMode]: isThin,
          [styles.headerStyle]: headerStyle,
          [styles.selectOpened]: isOpen && !showErrorMessages,
          [styles.errorShowed]: warnAboutError,
          [styles.disabled]: noItemsToSelect,
          [styles.noWrapper]: noWrapper,
        })}
        onMouseDown={
          noItemsToSelect
            ? () => null
            : (event) => {
                event.preventDefault();
                toggleOpen();
              }
        }
      >
        <div
          className={cx(styles.placeholder, {
            [styles.headerStyleMoved]: headerStyle && isOpen,
            [styles.placeholderHidden]: label && (isOpen || (typeof currentValue !== 'undefined' && currentValue !== null)),
            [styles.placeholderMoved]: isOpen || (typeof currentValue !== 'undefined' && currentValue !== null),
            [styles.placeholderWithSearch]: withSearch,
          })}
        >
          {placeholder}
        </div>
        <div
          className={cx(styles.hint, {
            [styles.value]: typeof currentValue !== 'undefined',
            [styles.hintThinOrLabel]: isThin || (!isThin && label),
            [styles.hintThinWithLabel]: (isThin && label) || (isThin && !placeholder),
          })}
        >
          {withSearch && (
            <NextIcon
              src="/icons/system/MagnifyingGlassSoft.svg"
              size={23}
              alt="search"
              customClass={cx(styles.searchIcon, { [styles.iconWithLabel]: label })}
            />
          )}
          <div className={cx(styles.captionContainer, {[styles.captionWithIcon]: withSearch})}>{withSearch && isOpen ? <DropdownSearchInput onSearch={onSearch} /> : showOptionCaption()}</div>
        </div>
        <DropdownCaret isOpen={isOpen} />
      </div>
      {isOpen && (
        <>
          <DropdownOptionList
            isThin={isThin}
            isOpen={isOpen}
            items={filteredOptions}
            label={label}
            trackChanges={trackChanges}
            currentValue={currentValue}
            toggleOpen={toggleOpen}
            shiftRight={shiftDropdownOptionsRight(containerRef as MutableRefObject<HTMLDivElement>)}
          />
        </>
      )}
      {showErrorMessages && (
        <div className={styles.errorWrapper}>
          <p className={styles.errorMessage}>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
